<template>
  <v-list dense>
    <v-list-item :to="{ name: 'ordersList' }" v-if="isOperator">
      <v-list-item-action
        ><v-icon>mdi-ticket-confirmation-outline</v-icon></v-list-item-action
      >
      <v-list-item-content
        ><v-list-item-title>Заказы</v-list-item-title></v-list-item-content
      >
    </v-list-item>
    <v-list-item :to="{ name: 'ordersListCompact' }" v-if="isCompactOrders">
      <v-list-item-action
        ><v-icon>mdi-ticket-confirmation-outline</v-icon></v-list-item-action
      >
      <v-list-item-content
        ><v-list-item-title>Заказы</v-list-item-title></v-list-item-content
      >
    </v-list-item>
    <v-list-group
      no-action
      prepend-icon="mdi-label-percent-outline"
      v-if="isExtraCharge"
    >
      <template v-slot:activator>
        <v-list-item-title>Наценки</v-list-item-title>
      </template>
      <v-list-item :to="{ name: 'extraChargesList' }" link>
        <v-list-item-title>Наценки</v-list-item-title>
        <v-list-item-icon
          ><v-icon>mdi-label-percent-outline</v-icon></v-list-item-icon
        >
      </v-list-item>
      <v-list-item :to="{ name: 'extraChargeTablesList' }" link>
        <v-list-item-title>Наценки Excel</v-list-item-title>
        <v-list-item-icon
          ><v-icon>mdi-file-excel-outline</v-icon></v-list-item-icon
        >
      </v-list-item>
      <v-list-item
        :to="{ name: 'regularExtraChargeTablesList' }"
        link
        v-if="isAdmin"
      >
        <v-list-item-title>Регулярка Excel</v-list-item-title>
        <v-list-item-icon
          ><v-icon>mdi-file-excel-outline</v-icon></v-list-item-icon
        >
      </v-list-item>
    </v-list-group>
    <v-list-group no-action prepend-icon="mdi-cart-plus" v-if="isOperator">
      <template v-slot:activator>
        <v-list-item-title>Доп. услуги</v-list-item-title>
      </template>
      <v-list-item :to="{ name: 'baggageInsuranceListPage' }" link>
        <v-list-item-title>Багаж</v-list-item-title>
        <v-list-item-icon
          ><v-icon>mdi-shield-airplane</v-icon></v-list-item-icon
        >
      </v-list-item>
      <v-list-item :to="{ name: 'tripCancelInsuranceListPage' }" link>
        <v-list-item-title>Отмена поездки</v-list-item-title>
        <v-list-item-icon><v-icon>mdi-shield-remove</v-icon></v-list-item-icon>
      </v-list-item>
      <v-list-item :to="{ name: 'medicalInsuranceListPage' }" link>
        <v-list-item-title>Медицинские</v-list-item-title>
        <v-list-item-icon><v-icon>mdi-hospital-box</v-icon></v-list-item-icon>
      </v-list-item>
      <v-list-item :to="{ name: 'aeroExpressTickets' }" link>
        <v-list-item-title>Аэроэкспресс</v-list-item-title>
        <v-list-item-icon><v-icon>mdi-train</v-icon></v-list-item-icon>
      </v-list-item>
    </v-list-group>
    <v-list-group no-action prepend-icon="mdi-filter-outline" v-if="isOperator">
      <template v-slot:activator>
        <v-list-item-title>Стоп-фильтры</v-list-item-title>
      </template>
      <v-list-item :to="{ name: 'searchFiltersList' }" link>
        <v-list-item-title>Поиски</v-list-item-title>
        <v-list-item-icon><v-icon>mdi-magnify</v-icon></v-list-item-icon>
      </v-list-item>
      <v-list-item :to="{ name: 'flightFiltersList' }" link>
        <v-list-item-title>Билеты</v-list-item-title>
        <v-list-item-icon><v-icon>mdi-playlist-minus</v-icon></v-list-item-icon>
      </v-list-item>
    </v-list-group>
    <v-list-item :to="{ name: 'manualFlightGroupsList' }" v-if="isOperator">
      <v-list-item-action
        ><v-icon>mdi-airplane-takeoff</v-icon></v-list-item-action
      >
      <v-list-item-content
        ><v-list-item-title
          >Ручные рейсы</v-list-item-title
        ></v-list-item-content
      >
    </v-list-item>
    <v-list-item :to="{ name: 'waitListPricesList' }" v-if="isAdmin">
      <v-list-item-action><v-icon>mdi-currency-rub</v-icon></v-list-item-action>
      <v-list-item-content
        ><v-list-item-title>Цены WL</v-list-item-title></v-list-item-content
      >
    </v-list-item>
    <v-list-item :to="{ name: 'promoCodesList' }" v-if="isPromoCode">
      <v-list-item-action><v-icon>mdi-sale</v-icon></v-list-item-action>
      <v-list-item-content
        ><v-list-item-title>Промокоды</v-list-item-title></v-list-item-content
      >
    </v-list-item>
    <v-list-item
      :to="{ name: 'baggageAllowancesList' }"
      v-if="isBaggageAllowance"
    >
      <v-list-item-action><v-icon>mdi-bag-suitcase</v-icon></v-list-item-action>
      <v-list-item-content
        ><v-list-item-title
          >Нормы багажа</v-list-item-title
        ></v-list-item-content
      >
    </v-list-item>
    <v-list-item
      :to="{ name: 'tariffDescriptionsList' }"
      v-if="isTariffDescription"
    >
      <v-list-item-action><v-icon>mdi-image-text</v-icon></v-list-item-action>
      <v-list-item-content
        ><v-list-item-title
          >Описания тарифов</v-list-item-title
        ></v-list-item-content
      >
    </v-list-item>
    <v-list-item :to="{ name: 'currenciesList' }" v-if="isCurrencies">
      <v-list-item-action><v-icon>mdi-currency-usd</v-icon></v-list-item-action>
      <v-list-item-content
        ><v-list-item-title>Курсы валют</v-list-item-title></v-list-item-content
      >
    </v-list-item>
    <v-list-item :to="{ name: 'agentsList' }" v-if="isAdmin">
      <v-list-item-action
        ><v-icon>mdi-account-tie-outline</v-icon></v-list-item-action
      >
      <v-list-item-content
        ><v-list-item-title>Агенты</v-list-item-title></v-list-item-content
      >
    </v-list-item>
    <v-list-group no-action v-if="isOperator">
      <template v-slot:activator>
        <v-list-item-action
          ><v-icon>mdi-cog-outline</v-icon></v-list-item-action
        >
        <v-list-item-title>Настройки</v-list-item-title>
      </template>
      <v-list-item :to="{ name: 'clientSettingsListPage' }">
        <v-list-item-content
          ><v-list-item-title
            >Доступность клиентов</v-list-item-title
          ></v-list-item-content
        >
      </v-list-item>
      <v-list-item :to="{ name: 'providerSettingsListPage' }">
        <v-list-item-content
          ><v-list-item-title
            >Доступность поставщиков</v-list-item-title
          ></v-list-item-content
        >
      </v-list-item>
      <v-list-item :to="{ name: 'pairSettingsListPage' }">
        <v-list-item-content
          ><v-list-item-title
            >Доступность связок</v-list-item-title
          ></v-list-item-content
        >
      </v-list-item>
      <v-list-item
        :to="{ name: 'portbiletSettingsList' }"
        v-if="isPortbiletSettings"
      >
        <v-list-item-content
          ><v-list-item-title
            >Настройки регулярки</v-list-item-title
          ></v-list-item-content
        >
      </v-list-item>
      <v-list-item :to="{ name: 'directionWhitelistList' }" v-if="isOperator">
        <v-list-item-content
          ><v-list-item-title
            >Настройки направлений</v-list-item-title
          ></v-list-item-content
        >
      </v-list-item>
      <v-list-item :to="{ name: 'charterSettingsList' }" v-if="isAdmin">
        <v-list-item-content
          ><v-list-item-title
            >Настройки чартеров</v-list-item-title
          ></v-list-item-content
        >
      </v-list-item>
      <v-list-item :to="{ name: 'configPage' }" v-if="isConfig">
        <v-list-item-content
          ><v-list-item-title
            >Конфигурация</v-list-item-title
          ></v-list-item-content
        >
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import authService from "@/services/AuthService";

@Component({
  components: {},
})
export default class Sidebar extends Vue {
  get isAdmin(): boolean {
    return authService.hasRole("ROLE_ADMIN");
  }

  get isConfig(): boolean {
    return authService.hasRole("ROLE_CONFIG");
  }

  get isPromoCode(): boolean {
    return authService.hasRole("ROLE_PROMO_CODE");
  }

  get isBaggageAllowance(): boolean {
    return authService.hasRole("ROLE_BAGGAGE_ALLOWANCE");
  }

  get isTariffDescription(): boolean {
    return authService.hasRole("ROLE_TARIFF_DESCRIPTION");
  }

  get isOperator(): boolean {
    return authService.hasRole("ROLE_OPERATOR");
  }

  get isCompactOrders(): boolean {
    return authService.hasRole("ROLE_ORDERS_COMPACT");
  }

  get isExtraCharge(): boolean {
    return authService.hasRole("ROLE_EXTRA_CHARGE");
  }

  get isPortbiletSettings(): boolean {
    return authService.hasRole("ROLE_PORTBILET_SETTINGS");
  }

  get isCurrencies(): boolean {
    return authService.hasRole("ROLE_CURRENCIES_VIEW");
  }
}
</script>

<style lang="less" scoped></style>
